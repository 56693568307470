import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useCommunityChatStore = defineStore('communityChat', {
  actions: {
		index: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/community-chats`),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/community-chats/${id}`),
    store: (communityChat) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/community-chats`, communityChat),
		update: (id, communityChat) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/community-chats/${id}`, communityChat),
  }
})
