<script setup>

import { onMounted, reactive, ref } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { useCommunityChatStore } from "@/store/group-chat/community-chat"
import { useTagStore } from "@/store/tag"
import { useToast } from "primevue/usetoast"

import { providers as configProviders } from "@/config"

const route = useRoute()
const router = useRouter()
const tagStore = useTagStore()
const toast = useToast()

const communityChatStore = useCommunityChatStore()
const communityChat = reactive({})

const creating = ref(false)

const optionBoolean = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]
const loading = ref(false)
const uploadDisabled = ref(false)

const tag = reactive({
  loading: false,
  tags: [],
})

let oldPicture = null
const uploader = (event) => {
  uploadDisabled.value = true
  const reader = new FileReader()
  reader.readAsDataURL(event.files[event.files.length - 1])
  reader.onload = function () {

    communityChat['picture'] = reader.result
    uploadDisabled.value = false
  }
  reader.onerror = function () {
    uploadDisabled.value = false
  }
}

const uploadRemove = () => {
  if (oldPicture !== null) communityChat['picture'] = oldPicture
  else delete communityChat['picture']
}

const save = () => {
  const action = creating.value
    ? communityChatStore.store(communityChat)
    : communityChatStore.update(route.params.id, communityChat)
  loading.value = true
  action.then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      loading.value = false
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

    oldPicture = data.community_chat.picture_group_url

    if (creating.value) {
      setTimeout(() => {
        communityChat['id'] = data.community_chat.id
        communityChat['picture'] = data.community_chat.picture_group_url
        communityChat['can_join'] = false
        communityChat['can_message'] = false
        communityChat['active'] = true

        router.replace({ name: 'community-chats.edit', params: { id: data.community_chat.id } })
        creating.value = false
        loading.value = false
      }, 1000)

      return
    }

    loading.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

onMounted(() => {
  creating.value = (typeof route.params.id === 'undefined' && typeof communityChat.id === 'undefined')

  loading.value = true
  if (typeof route.params.id !== 'undefined') {
    communityChatStore.show(route.params.id)
      .then((res) => {
        const _communityChat = res.data.community_chat

        communityChat['id'] = _communityChat.id
        communityChat['picture_group_url'] = _communityChat.picture_group_url
        communityChat['title'] = _communityChat.title
        communityChat['can_join'] = _communityChat.can_join
        communityChat['can_message'] = _communityChat.can_message
        communityChat['active'] = _communityChat.active
        communityChat['providers'] = _communityChat.providers
        communityChat['tags'] = _communityChat.tags || []

        communityChat['picture'] = communityChat['picture_group_url']
        oldPicture = communityChat['picture_group_url']

        loading.value = false
      })
      .catch(() => loading.value = false)

    tagStore.indexAll().then((res) => {
      tag.tags = res.data.tags.map((_tag) => _tag.name)

      tag.loading = false
    })
  } else {
    loading.value = false
  }
})

const providers = ref(configProviders);

</script>
<template>
  <div class="block-section">
    <div class="block-header">
        <span v-if="communityChat.id" class="block-title">
            <div>Edit #{{ communityChat.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>

    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div>
            <Image v-if="communityChat.picture" :src="communityChat.picture" preview alt="Image Text" class="mb-3" style="width: 100%"/>
            <FileUpload
              v-if="! creating"
              accept="image/*"
              :customUpload="true"
              :auto="true"
              :showUploadButton="false"
              :disabled="uploadDisabled"
              @uploader="uploader"
              @clear="uploadRemove"
            >
            </FileUpload>

            <label class="block text-900 font-medium mb-2" for="title">Title</label>
            <InputText v-model="communityChat.title" id="title" type="text" class="w-full mb-3" />

            <div v-if="! creating">
              <label class="block text-900 font-medium mb-2" for="can_join">Join</label>
              <SelectButton v-model="communityChat.can_join" id="can_join" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" />

              <label class="block text-900 font-medium mb-2" for="can_message">Message</label>
              <SelectButton v-model="communityChat.can_message" id="can_message" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" />

              <label class="block text-900 font-medium mb-2" for="active">Active</label>
              <SelectButton v-model="communityChat.active" id="active" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" />

              <label class="block text-900 font-medium mb-2" for="providers">Providers</label>
              <MultiSelect v-model="communityChat.providers" display="chip" :options="providers" class="w-full mb-3" />

              <label class="block text-900 font-medium mb-2" for="tags">Tags</label>
              <MultiSelect v-model="communityChat.tags" display="chip" :loading="tag.loading" :options="tag.tags" class="w-full mb-3" />
            </div>

          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="loading" @click="save()" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.p-image img {
  width: 100%;
  border: 1px solid #dee2e6 !important;
}
.p-fileupload-buttonbar {
  border-bottom: 1px solid #dee2e6 !important;
  margin-bottom: 1rem;
}
.p-fileupload-content {
  display: none;
}
</style>
